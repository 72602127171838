import React from "react";
import ProductsAndServices from "../components/ProductsAndServices";
import Layout from "../components/Layout";
import TitleAndDesc from "../components/TitleAndDescription";
import { useProductsOnlyQuery } from "../hooks/WhatWeDo/useProductsOnlyQuery";
import { useServicesOnlyQuery } from "../hooks/WhatWeDo/useServicesOnlyQuery";

//   This is the main what we do page
// It queries products and services seperatly but displays them underneath one another

const WhatWeDo = () => {
	// seperate query for products
	const productData = useProductsOnlyQuery();
	console.log("all the products", productData);

	const arrayOfProducts = productData.allWpWhatWeDo.edges;
	console.log("the products", arrayOfProducts);

	// seperate query for services
	const serviceData = useServicesOnlyQuery();
	console.log("all the services", serviceData);

	const arrayOfServices = serviceData.allWpWhatWeDo.edges;
	console.log("the services", arrayOfServices);

	return (
		<Layout>
			<TitleAndDesc title={"products & Services"} desc={""} />
			{/* products */}
			<div>
				<TitleAndDesc title={"Our data products"} />
				{arrayOfProducts.map((product) => {
					return (
						<ProductsAndServices
							title={product.node.title}
							image={product.node.featuredImage.node.gatsbyImage}
							description={product.node.excerpt}
							link={product.node.uri}
						/>
					);
				})}
			</div>
			<br></br>

			{/* services */}
			<div>
				<TitleAndDesc title={"Our consulting services"} />
				{arrayOfServices.map((service) => {
					return (
						<ProductsAndServices
							title={service.node.title}
							image={service.node.featuredImage.node.gatsbyImage}
							description={service.node.excerpt}
							link={service.node.uri}
						/>
					);
				})}
			</div>
		</Layout>
	);
};

export default WhatWeDo;
