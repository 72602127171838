import { useStaticQuery, graphql } from "gatsby";

export const useServicesOnlyQuery = () => {
	const data = useStaticQuery(graphql`
		query {
			allWpWhatWeDo(
				filter: { categories: { nodes: { elemMatch: { slug: { eq: "services" } } } } }
			) {
				edges {
					node {
						id
						uri
						title
						excerpt
						featuredImage {
							node {
								gatsbyImage(width: 300)
							}
						}
					}
				}
			}
		}
	`);
	return data;
};
